import Arr = require("Everlaw/Core/Arr");
import Base = require("Everlaw/Base");
import Cmp = require("Everlaw/Core/Cmp");
import Dom = require("Everlaw/Dom");
import DomText = require("Everlaw/Dom/Text");
import Input = require("Everlaw/Input");
import Metadata = require("Everlaw/Metadata");
import { NO_VALUE } from "Everlaw/SearchConstants";
import { TextParams } from "Everlaw/UI/Validated";
import Rest = require("Everlaw/Rest");
import Str = require("Everlaw/Core/Str");
import TextBox = require("Everlaw/UI/TextBox");
import UI_Validated = require("Everlaw/UI/Validated");
import Widget = require("Everlaw/UI/Widget");
import XRegExp = require("xregexp");

import FocusContainerWidget = require("Everlaw/UI/FocusContainerWidget");
import SingleSelect = require("Everlaw/UI/SingleSelect");
import ComboBox = require("Everlaw/UI/ComboBox");

interface Bates {
    prefix: string;
    number: Bates.Number;
    suffix: string;
    page?: Bates.Page;
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable no-inner-declarations */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module Bates {
    /**
     * Corresponds to a document's begin Bates (as opposed to one of its metadata fields).
     */
    // TODO: Currently, this does not account for user per-doc access permissions. e.g. the Bates/Control
    //  search term always displays all prefixes in the project.
    export class Prefix extends Base.Object {
        static readonly ANY_PREFIX = new Base.Primitive("(Any prefix)");

        // This constant reflects the PrefixMaxLen property in Bates.properties
        static readonly MAX_LEN = 36;

        /**
         * Matches `TextValue#NULL_VALUE` on the backend. Used to denote a null value search.
         */
        static readonly NO_PREFIX = new Base.Primitive(NO_VALUE);

        get className() {
            return "BatesPrefix";
        }
        override id: string;

        getPrefix() {
            return this.id;
        }

        override display() {
            return this.getPrefix();
        }
    }

    /**
     * To distinguish between prefixes for different Bates metadata fields, the `id` field includes
     * both the prefix string and the field ID string. As such, use the `get*` methods from this class
     * instead of directly using `Base.get` or `id`.
     */
    export class MetadataPrefix extends Prefix {
        private static readonly SEPARATOR = "_"; // matches SEPARATOR in BatesPrefix.java

        override get className() {
            return "MetadataBatesPrefix";
        }

        /**
         * Unlike in the parent class Prefix, the `id` field is literally an identifier, and not (just)
         * the prefix value. It is in the format
         * <p>
         * fieldId + SEPARATOR + prefixStr
         * <p>
         * e.g. "673_my-prefix".
         */
        override getPrefix() {
            const separatorIndex = this.id.indexOf(MetadataPrefix.SEPARATOR);
            return this.id.substring(separatorIndex + 1);
        }

        static getPrefixObj(prefix: string, field: Metadata.Field) {
            const id = `${field.id}${MetadataPrefix.SEPARATOR}${prefix}`;
            return Base.get(MetadataPrefix, id);
        }

        static getPrefixObjs(field: Metadata.Field): Prefix[] {
            const fieldStr = field.id.toString();
            return Base.get(MetadataPrefix).filter((prefix) => Str.startsWith(prefix.id, fieldStr));
        }
    }

    export function compare(b1: Bates, b2: Bates) {
        if (!b2) {
            return -1;
        } else if (!b1) {
            return 1;
        }
        const prefixCmp = b1.prefix.localeCompare(b2.prefix, undefined, {
            numeric: true,
            sensitivity: "base",
        });
        if (prefixCmp === 0) {
            const numberCmp = b1.number.compare(b2.number);
            if (numberCmp === 0 && b1.page && b2.page) {
                const pageCmp = b1.page.compare(b2.page);
                if (pageCmp === 0 && b1.suffix && b2.suffix) {
                    return b1.suffix.localeCompare(b2.suffix, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                }
                return pageCmp;
            }
            return numberCmp;
        }
        return prefixCmp;
    }

    const ZERO = { number: 0, digits: 0 };

    interface NumberPart {
        number: number;
        digits: number;
    }

    export const MAX_STANDARD_DIGITS = 15;
    export const MAX_STANDARD_NUMBER = Math.pow(10, MAX_STANDARD_DIGITS) - 1;
    //Duplicated in Bates.properties
    export const MAX_PAGE_DIGITS = 6;
    export const VALID_PAGE_SEPARATORS = "._-";

    export class Number {
        static STANDARD_PATTERN = `\\d{1,${MAX_STANDARD_DIGITS}}`;
        static ROLLING_PATTERN = "(?:\\d{1,6}\\.){1,2}\\d{1,6}";
        static PATTERN = Number.ROLLING_PATTERN + "|" + Number.STANDARD_PATTERN;

        /**
         * Matches a string if it can be parsed as a Bates number.
         */
        static REGEX = new RegExp(`^(?:${Number.PATTERN})$`);

        /**
         * Finds a Bates number inside a string. For use in loops to incrementally find Bates numbers.
         */
        static INLINE_REGEX = new RegExp(`\\b(?:${Number.PATTERN})\\b`);

        /**
         * Inverse of toString() and toJSON(); returns null when value is not a valid Bates.Number.
         */
        static fromString(value: string) {
            return Bates.Number.REGEX.test(value)
                ? new Bates.Number(
                      value
                          .split(".")
                          .map((n) => ({
                              number: +n,
                              digits: n.length,
                          }))
                          .reverse(),
                  )
                : null;
        }

        /**
         * Creates a Standard bates number with the given number and digits. Returns null when the
         * values are not valid.
         */
        static standard(number: number, digits: number) {
            if (
                number < 0
                || number > MAX_STANDARD_NUMBER
                || digits < 1
                || digits > MAX_STANDARD_DIGITS
            ) {
                return null;
            }
            return new Bates.Number([{ number, digits }]);
        }

        /** The least significant part comes first, to make comparison and addition easier */
        constructor(public parts: NumberPart[]) {}

        isRolling() {
            return this.parts.length > 1;
        }

        compare(n: Bates.Number) {
            // Standard bates numbers sort before Rolling bates numbers
            let cmp = Cmp.bool(this.isRolling(), n.isRolling());
            if (cmp !== 0) {
                return cmp;
            }
            // Start comparison from most significant digits, which come last.
            for (let i = Math.max(this.parts.length, n.parts.length) - 1; i >= 0; i--) {
                cmp = Cmp.num((this.parts[i] || ZERO).number, (n.parts[i] || ZERO).number);
                if (cmp !== 0) {
                    return cmp;
                }
            }
            return 0;
        }

        toJSON() {
            return this.toString();
        }
        toString() {
            return this.joinParts(pad);
        }
        displayShort() {
            return this.joinParts((p) => String(p.number));
        }
        private joinParts(stringifyPart: (p: NumberPart) => string) {
            return this.parts.map(stringifyPart).reverse().join(".");
        }

        /**
         * Returns the number that results from increasing this by the given amount, which must be >= 0.
         *
         * This implementation matches BatesNumber#implicitPage on the backend. The frontend only
         * operates on Bates numbers that (1) don't include pages, and (2) can be implicitly numbered.
         * Document#canTransferBates ensures this condition for Document bates numbers.
         */
        add(amount: number) {
            if (this.isRolling()) {
                return new Bates.Number(
                    this.parts.map((p) => {
                        if (amount === 0) {
                            return p;
                        }
                        let sum = p.number + amount;
                        const max = Math.pow(10, p.digits) - 1;
                        // count number of times the number must wrap from ...999 to ...001
                        amount = Math.floor((sum - 1) / max);
                        sum -= amount * max;
                        return {
                            number: sum,
                            digits: p.digits,
                        };
                    }),
                );
            }
            return new Bates.Number([
                {
                    number: this.parts[0].number + amount,
                    digits: this.parts[0].digits,
                },
            ]);
        }
    }

    /** MAX_STANDARD_DIGITS 0s **/
    const PADDING = String(Math.pow(10, MAX_STANDARD_DIGITS)).slice(1);
    function pad(p: NumberPart) {
        const s = String(p.number);
        return PADDING.slice(0, Math.max(0, p.digits - s.length)) + s;
    }

    export interface PageJson {
        separator: string;
        number: number;
        digits: number;
    }

    export class Page {
        separator: string;
        number: number;
        digits: number;

        constructor(separator: string, num: number, digits: number) {
            this.separator = separator;
            this.number = num;
            this.digits = digits;
        }

        static fromString(pg: string): Page {
            const separator = pg.charAt(0);
            const num = +pg.substring(1);
            const digits = pg.length - 1;
            return new Bates.Page(separator, num, digits);
        }

        static fromJson(value: PageJson): Page {
            return new Page(value.separator, value.number, value.digits);
        }

        toString() {
            return this.separator + pad(this);
        }

        toJSON(): PageJson {
            return {
                separator: this.separator,
                number: this.number,
                digits: this.digits,
            };
        }
        compare(op: Page) {
            if (!op) {
                return -1;
            }
            const dgtCmp = this.digits - op.digits;
            if (dgtCmp === 0) {
                return this.number - op.number;
            }
            return dgtCmp;
        }
    }

    export function toDebugString(bates: Bates) {
        return (
            `pre:${bates.prefix} num:${bates.number.toString()}`
            + ` page:${bates.page ? bates.page.toString() : ""}`
            + ` suf:${bates.suffix ? bates.suffix : ""}`
            + ` rolling:${bates.number.isRolling()}`
        );
    }

    /**
     * Matches `Bates#NO_PREFIX` on the back-end. We don't display the prefix for Bates that have
     * this special prefix.
     */
    export const NO_PREFIX = "(EMPTY PREFIX)";

    /**
     * Matches `Bates#LEGACY_NO_PREFIX` on the back-end. Needs to be removed eventually.
     * Refer to `Bates.java#LEGACY_NO_PREFIX` for further description.
     */
    export const LEGACY_NO_PREFIX = "NO_PREFIX";

    export function display(bates: Bates, withSuffix = true, withPage = true) {
        let prefix = bates.prefix;
        // TODO: eventually remove comparison with LEGACY_NO_PREFIX
        if (prefix === NO_PREFIX || prefix === LEGACY_NO_PREFIX) {
            prefix = "";
        }
        return (
            prefix
            + (bates.number.isRolling() ? "." : "")
            + bates.number.toString()
            + (bates.page && withPage ? bates.page.toString() : "")
            + (bates.suffix && withSuffix ? bates.suffix : "")
        );
    }

    export class NumberWidget extends UI_Validated.Text {
        constructor(params?: UI_Validated.ValidatedTextBoxParams) {
            super(
                Object.assign(
                    {
                        name: "bates number",
                        validator: (value: string) => Bates.Number.REGEX.test(value),
                        // TODO: fix types so that type cast below isn't necessary
                    } as TextParams,
                    params,
                ),
            );
        }
        getNumberValue() {
            return Bates.Number.fromString(super.getValue());
        }
        override setValue(val: Bates.Number) {
            super.setValue(val.toString());
        }
    }

    export interface NumberRange {
        begin?: Bates.Number;
        end?: Bates.Number;
    }

    /**
     * Allows the user to input multiple (possibly open-ended) Bates.NumberRange values.
     */
    export class NumbersAndRangesWidget extends Widget implements Widget.WithSettableValue {
        /**
         * Characters to exclude while parsing Bates with default parsing.
         */
        private static readonly FORBIDDEN_PREFIX_CHARS = ", -";

        blurOnSubmit = true;
        box: TextBox;
        private readonly isForMetadata: boolean;

        constructor(field?: Metadata.Field) {
            super();
            this.isForMetadata = !!field;
            this.box = new TextBox({
                placeholder: "Numbers or ranges (e.g. 1, 2-4, 5-)",
                focusOnTap: true,
                textBoxAriaLabel:
                    "Bates or Control, Enter numbers or ranges (e.g. 1, or 2 hyphen 4, or 5 hyphen)",
            });
            this.box.onSubmit = (val) => {
                if (this.blurOnSubmit) {
                    Dom.blur();
                }
            };
            this.box.onBlur = () => this.onBlur();
            this.box.onPaste = (evt: ClipboardEvent) => {
                evt.preventDefault();
                this.box.replaceSelection(
                    Input.getClipboard(evt, "text").replace(/(?:\r\n|\r|\n)+/g, " "),
                );
            };
        }

        getValue(): Bates.NumberRange[] {
            return this.parseRanges(this.rawValue());
        }

        setValue(ranges: Bates.NumberRange[]) {
            this.box.setValue(this.displayRanges(ranges));
        }

        override getNode() {
            return this.box.getNode();
        }

        override destroy() {
            this.box.destroy();
        }

        override focus() {
            this.box.focus();
        }

        rawValue() {
            return this.box.getValue();
        }

        onBlur() {}

        setWidth(width: string) {
            this.box.setWidth(width);
        }

        /**
         * Returns a list of parsed Bates number ranges, or empty if the input contains no valid ranges.
         */
        private parseRanges(value: string): Bates.NumberRange[] {
            // TODO: For now, we discard the bates prefixes that the user pastes into the box, but
            //  eventually we should find a sensible way to retain them as part of the query.
            const numbers: Bates.Number[] = [];
            const remainders: string[] = [];
            this.decomposeIntoNumbersAndRemainders(value, numbers, remainders);
            return this.rangesFromDecomposition(numbers, remainders);
        }

        /**
         * Parse `value` into n Bates numbers and n+1 remainders (the trimmed substrings before, between
         * and after the Bates numbers), adding them to `numbers` and `remainders` respectively. Any
         * Bates prefixes or suffixes included in the input are discarded (though the numbers are
         * retained).
         *
         * example:
         * value = "ASDF1-QWER5, 21"
         * -> numbers = [1, 5, 21], remainders = ["", "-", ",", ""]
         */
        private decomposeIntoNumbersAndRemainders(
            value: string,
            numbers: Bates.Number[],
            remainders: string[],
        ): void {
            // Parse using prefixes in project. If using this widget for Bates type metadata or in
            // a project-less context, use default parsing instead.
            // TODO: Ideally, for Bates metadata we would parse with a regex constructed from the
            //  prefixes that appear in that field. Currently, the logic for constructing such a
            //  regex resides on the back-end.
            const defaultParsing = this.isForMetadata || !Bates.PROJECT_REGEX;
            const batesRegex = defaultParsing ? Bates.DEFAULT_REGEX : Bates.PROJECT_REGEX;

            let pos = 0;
            // Each iteration, match a Bates or Bates number, and advance pos to the end of that match.
            while (pos < value.length) {
                const regexSearchStart = this.adjustedSearchPosition(value, pos);
                if (regexSearchStart === value.length) {
                    break;
                }

                const batesMatch = XRegExp.exec(value, batesRegex, regexSearchStart);
                const numMatch = XRegExp.exec(value, Bates.Number.INLINE_REGEX, regexSearchStart);

                let currMatch: RegExpExecArray;
                let matchLength = 0;
                if (numMatch && (!batesMatch || numMatch.index <= batesMatch.index)) {
                    numbers.push(Bates.Number.fromString(numMatch[0]));
                    currMatch = numMatch;
                } else if (batesMatch) {
                    const b = Bates.fromMatch(batesMatch);
                    numbers.push(b.number);
                    currMatch = batesMatch;
                    matchLength -= this.matchLengthCorrection(b, defaultParsing);
                } else {
                    break;
                }
                remainders.push(value.substring(pos, currMatch.index).trim());
                matchLength += currMatch[0].length;
                pos = currMatch.index + matchLength;
            }
            remainders.push(value.substring(pos).trim());
        }

        /**
         * Returns the smallest index greater than or equal to `pos` such that the corresponding
         * character in `value` is not space, comma, or "-".
         *
         * It's necessary to ignore these characters when using default parsing; otherwise they may be
         * parsed as part of a prefix. e.g. in "10, -5", the ", -5" would get parsed as Bates with
         * prefix ", -".
         */
        private adjustedSearchPosition(value: string, pos: number): number {
            let adjusted = pos;
            while (adjusted < value.length) {
                const currChar = value.charAt(adjusted);
                const isForbidden =
                    NumbersAndRangesWidget.FORBIDDEN_PREFIX_CHARS.indexOf(currChar) >= 0;
                if (!isForbidden) {
                    break;
                }
                adjusted++;
            }
            return adjusted;
        }

        /**
         * The number of indices to correct the length of the current match. Nonzero in the case that we
         * erroneously included the '-' and the next number's prefix as the suffix of the current Bates.
         */
        private matchLengthCorrection(bates: Bates, defaultParsing: boolean): number {
            const suffix = bates.suffix;
            if (!suffix || !Str.startsWith(suffix, "-")) {
                return 0;
            }
            const withoutSeparator = suffix.slice(1);
            const matchesPrefix =
                defaultParsing
                || Base.get(Bates.Prefix).some((prefix) => prefix.getPrefix() === withoutSeparator);
            return matchesPrefix ? suffix.length : 0;
        }

        /**
         * Given n `numbers` and the n+1 `remainders` before, after, and between them, return the
         * corresponding `NumberRange`s
         */
        private rangesFromDecomposition(numbers, remainders): Bates.NumberRange[] {
            const ranges: Bates.NumberRange[] = [];
            const n = numbers.length;
            for (let i = 0; i < n; i++) {
                const num = numbers[i];
                const leftRemainder = remainders[i];
                const rightRemainder = remainders[i + 1];
                if (Str.endsWith(leftRemainder, "-")) {
                    // open-ended begin
                    ranges.push({ end: num });
                } else if (rightRemainder === "-" && i < n) {
                    // full range
                    ranges.push({ begin: num, end: numbers[++i] });
                } else if (Str.startsWith(rightRemainder, "-") /* e.g., '-,' */) {
                    // open-ended end
                    ranges.push({ begin: num });
                } else {
                    // single number
                    ranges.push({ begin: num, end: num });
                }
            }
            return ranges;
        }

        private displayRanges(ranges: Bates.NumberRange[]) {
            return (ranges || [])
                .map((range) => {
                    const begin = range.begin ? range.begin.displayShort() : "";
                    const end = range.end ? range.end.displayShort() : "";
                    return begin === end ? begin : begin + "-" + end;
                })
                .filter((s) => !!s)
                .join(", ");
        }
    }

    export interface BatesSearch {
        prefix: string;
        ranges: Bates.NumberRange[];
    }

    /**
     * Widget for inputting a Bates prefix together with ranges of Bates numbers. Classes extending this
     * should implement the widget used for prefix input.
     */
    export abstract class BatesRangesWidget
        extends FocusContainerWidget
        implements Widget.WithSettableValue
    {
        protected readonly prefixWidget: Widget.WithSettableValue;
        protected readonly rangesWidget: NumbersAndRangesWidget;
        protected readonly field: Metadata.Field;

        constructor(field?: Metadata.Field) {
            super(Dom.div());
            this.field = field;
            const rangesWidget = new NumbersAndRangesWidget(field);
            this.prefixWidget = this.constructPrefixWidget(rangesWidget);
            this.rangesWidget = rangesWidget;

            Dom.style(this.node, { display: "flex" });
            Dom.style(this.prefixWidget, { width: "88px" });
            Dom.style(this.rangesWidget, { flex: "1", marginLeft: "10px" });
            Dom.place([this.prefixWidget, this.rangesWidget], this.node);
        }

        getValue(): BatesSearch {
            const prefix = this.getPrefix();
            const ranges = this.rangesWidget.getValue();
            if (prefix === null && ranges.length === 0) {
                return null;
            }
            return { prefix: prefix, ranges: ranges };
        }

        setValue(val: BatesSearch | Bates) {
            this.setPrefix(val.prefix);
            if ("ranges" in val) {
                this.setRanges(val.ranges);
            } else {
                // val can be Bates rather than BatesSearch if the value comes from exact search
                // (e.g. clicking an entry of the metadata table in the review window)
                this.setRanges([{ begin: val.number, end: val.number }]);
            }
        }

        setPrefix(prefix: string) {
            let prefixWidgetVal: Base.Object;
            switch (prefix) {
                case undefined:
                    prefixWidgetVal = Prefix.ANY_PREFIX;
                    break;
                case null:
                    prefixWidgetVal = Prefix.ANY_PREFIX;
                    break;
                case Prefix.NO_PREFIX.id:
                    prefixWidgetVal = Prefix.NO_PREFIX;
                    break;
                default:
                    prefixWidgetVal = this.getPrefixWidgetVal(prefix);
                    break;
            }
            this.prefixWidget.setValue(prefixWidgetVal);
        }

        setRanges(val: Bates.NumberRange[]) {
            this.rangesWidget.setValue(val);
        }

        override focus() {
            if (this.rangesWidget.rawValue()) {
                this.rangesWidget.focus();
            } else {
                this.prefixWidget.focus();
            }
        }

        override blur() {
            super.blur();
            this.prefixWidget.blur();
            this.rangesWidget.blur();
        }

        setWidth(width: string) {
            Dom.style(this.node, "width", width);
        }

        protected specialPrefixes(): Base.Object[] {
            return this.field ? [Prefix.ANY_PREFIX, Prefix.NO_PREFIX] : [Prefix.ANY_PREFIX];
        }

        abstract constructPrefixWidget(
            rangesWidget: NumbersAndRangesWidget,
        ): Widget.WithSettableValue;
        abstract getPrefix(): string;
        abstract getPrefixWidgetVal(prefix: string): any;
    }

    /**
     * Selects prefixes from a dropdown containing the prefixes in the project.
     */
    export class BatesRangesWidgetWithDropdown extends BatesRangesWidget {
        constructPrefixWidget(rangesWidget: NumbersAndRangesWidget): Widget.WithSettableValue {
            const prefixes = this.field
                ? [this.specialPrefixes(), Bates.MetadataPrefix.getPrefixObjs(this.field)]
                : [this.specialPrefixes(), Base.get(Prefix)];
            return new SingleSelect<Base.Object>({
                elements: prefixes,
                placeholder: "Prefix",
                selectOnSame: true,
                headers: false,
                popup: "after",
                initialSelected: Prefix.ANY_PREFIX,
                onSelect: (selected) => {
                    rangesWidget.box.setDisabled(selected === Prefix.NO_PREFIX);
                    setTimeout(() => rangesWidget.focus(), 0);
                },
                onTextBoxBlur: function () {
                    this.tb.moveCursor(0);
                },
                textBoxAriaLabel: "Bates or Control, Select prefix",
            });
        }

        getPrefix(): string {
            const prefixObj = this.prefixWidget.getValue();
            switch (prefixObj) {
                case Prefix.ANY_PREFIX:
                    return null;
                case Prefix.NO_PREFIX:
                    return prefixObj.id;
                default:
                    return (prefixObj as Prefix).getPrefix();
            }
        }

        getPrefixWidgetVal(prefix: string): any {
            return this.field
                ? MetadataPrefix.getPrefixObj(prefix, this.field)
                : Base.get(Prefix, prefix);
        }
    }

    /**
     * Allows any input as a prefix, and "(Any Prefix)" to search all prefixes.
     */
    export class BatesRangesWidgetWithPrefixInput extends BatesRangesWidget {
        constructPrefixWidget(rangesWidget: NumbersAndRangesWidget): Widget.WithSettableValue {
            return new ComboBox({
                elements: this.specialPrefixes(),
                placeholder: "Prefix",
                selectOnSame: true,
                headers: false,
                popup: "after",
                initialSelected: Prefix.ANY_PREFIX,
                onSelect: (selected) => {
                    rangesWidget.box.setDisabled(selected === Prefix.NO_PREFIX);
                    setTimeout(() => rangesWidget.focus(), 0);
                },
            });
        }

        getPrefix(): string {
            const prefix = this.prefixWidget.getValue();
            if (prefix === Prefix.ANY_PREFIX.id) {
                return null;
            }
            return prefix;
        }

        getPrefixWidgetVal(prefix: string): any {
            return prefix;
        }
    }

    /**
     * Matches a bates number with prefixes from the current project or any prefix(DEFAULT).
     * Pass the match to fromMatch to produce the corresponding Bates.
     */
    export const DEFAULT_REGEX =
        JSP_PARAMS.Bates.genericPrefixRegex && buildRegex(JSP_PARAMS.Bates.genericPrefixRegex, "i");
    export const EXACT_MATCH_DEFAULT_REGEX =
        JSP_PARAMS.Bates.genericPrefixRegex
        && buildRegex(JSP_PARAMS.Bates.genericPrefixRegex, "i", true);
    export const PROJECT_REGEX =
        JSP_PARAMS.Bates.projectRegex && XRegExp(JSP_PARAMS.Bates.projectRegex, "i");
    export const EXACT_MATCH_PROJECT_REGEX =
        JSP_PARAMS.Bates.projectRegex && XRegExp(`^(?:${JSP_PARAMS.Bates.projectRegex})$`, "i");
    export const EMPTY_PREFIX_ALLOWED_REGEX =
        JSP_PARAMS.Bates.emptyPrefixAllowedRegex
        && XRegExp(JSP_PARAMS.Bates.emptyPrefixAllowedRegex, "i");

    function buildRegex(prefixRegex: string, regexOptions = "ig", exactMatch = false): RegExp {
        if (!JSP_PARAMS.Bates.noPrefixRegex) {
            return null;
        }
        let pattern = `(?<prefix>${prefixRegex})${JSP_PARAMS.Bates.noPrefixRegex}`;
        if (exactMatch) {
            pattern = `^(?:${pattern})$`;
        }
        return XRegExp(pattern, regexOptions);
    }

    export function buildBatesRegex(prefixes: string[], regexOptions = "ig"): RegExp {
        const prefixStr = prefixes.map((prefix) => `\\Q${prefix}\\E`).join("|");
        return buildRegex(prefixStr, regexOptions);
    }

    /**
     * Extracts the bates number from the given match. This code must remain in sync with
     * BatesParser.fromMatch on the backend.
     */
    export function fromMatch(match: RegExpMatchArray): Bates {
        // XRegExp adds support for named groups, which we use.
        const m = <{ [group: string]: string }>(<any>match);
        let page: Bates.Page = null;
        const pgSep = findGroup(m, "pgSep");
        if (pgSep) {
            page = Bates.Page.fromString(pgSep + findGroup(m, "pgNum"));
        }
        return {
            prefix: m["prefix"] || NO_PREFIX,
            number: Bates.Number.fromString(
                Arr.filterNonNullish([m["number"], m["rbox"], m["rfolder"], m["rpage"]]).join("."),
            ),
            page: page,
            suffix: findGroup(m, "suffix"),
        };
    }

    /*
     * TODO: Implement page number increase. Currently not needed.
     */
    export function add(baseNumber: Bates, num: number) {
        return {
            prefix: baseNumber.prefix,
            number: baseNumber.number.add(num),
            page: baseNumber.page,
            suffix: baseNumber.suffix,
        };
    }

    export function findGroup(m: { [group: string]: string }, group: string) {
        for (let i = 1; i <= 4; i++) {
            const grp = m[group + i];
            if (grp) {
                return grp;
            }
        }
        return "";
    }

    export function parseBates(unparsedBates: string, anyPrefix?: boolean): Bates {
        const regex = anyPrefix ? Bates.EXACT_MATCH_DEFAULT_REGEX : Bates.EXACT_MATCH_PROJECT_REGEX;
        const batesMatch = XRegExp.exec(unparsedBates, regex, 0);
        return batesMatch ? fromMatch(batesMatch) : null;
    }

    export function toJson(bates: Bates): string {
        return JSON.stringify({
            prefix: bates.prefix,
            number: bates.number.toJSON(),
            suffix: bates.suffix,
            page: bates.page ? bates.page.toJSON() : null,
        });
    }

    interface CachedDocument {
        id: number;
        prefix: string;
        shortNumber: string;
        suffix?: string;
        page?: Bates.Page;
    }

    const cachedDocs: { [prefix: string]: { [shortNumber: string]: CachedDocument } } = {};

    // To be considered a valid bates for linking, either bates need have a nonempty prefix
    // or for empty prefix, the number should have digits >= 5 or has 2 or more leading 0s.
    // For example, for bates with empty prefix such as "5", 5 does not automatically
    // link but 005 or 0005 does. However, 57000 automatically links because it has
    // 5 or more digits.
    export function isValidBatesForLinking(bates: Bates) {
        const LINKING_DIGITS_THRESHOLD_FOR_NO_PREFIX = 5;
        return (
            bates.prefix !== Bates.NO_PREFIX
            || bates.number.parts[0].digits >= LINKING_DIGITS_THRESHOLD_FOR_NO_PREFIX
            || Str.startsWith(bates.number.toString(), "0")
        );
    }

    export function getMatch(text, pos): RegExpExecArray {
        let match: RegExpExecArray;
        if ((match = XRegExp.exec(text, Bates.PROJECT_REGEX, pos))) {
            return match;
        }
        return XRegExp.exec(text, Bates.EMPTY_PREFIX_ALLOWED_REGEX, pos);
    }

    // Consolidate requests to matchBates.rest to once every BATES_LINK_RATE_LIMIT_MS milliseconds.
    const BATES_LINK_RATE_LIMIT_MS = 40;
    let batesLinkTimeoutId: number | null = null;
    const batesLinkRateLimit = {
        nodeToCallback: new Map<Dom.Nodeable, () => void>(),
        lookups: new Map<string, Set<Number>>(), // Maps bates prefix to set of numbers
    };

    export function insertBatesLinks(
        node: HTMLElement,
        getLink: (docId: number, batesText: string) => HTMLElement,
        cacheOnly?: boolean,
    ) {
        if (!Bates.PROJECT_REGEX) {
            // We can't insert links because we can't parse bates numbers.
            return;
        }
        const lookups: Bates[] = cacheOnly ? null : [];
        DomText.walk(node, (textNode, parentNode) => {
            if (parentNode instanceof HTMLAnchorElement) {
                // already linkified
                return;
            }
            const text = textNode.nodeValue;
            let replacement: DocumentFragment;
            let pos = 0;
            let match: RegExpExecArray;
            while (pos < text.length && (match = getMatch(text, pos))) {
                const bates = Bates.fromMatch(match);
                const shortNumber = bates.number.displayShort();
                // Unless the actual document information indicates otherwise, we consider the end of
                // the match to be just after the prefix and number, ignoring the page and suffix. This
                // ensures that we handle cases where the suffix erroneously includes part of the next
                // bates number. For example, "EVER010-EVER020" would parse with "-EVER" as the suffix.
                let end =
                    match.index
                    + Bates.display({ prefix: bates.prefix, number: bates.number, suffix: "" })
                        .length;
                // Increment `end` if the user put a space between the prefix and number
                const prefixLength = bates.prefix === Bates.NO_PREFIX ? 0 : bates.prefix.length;
                const endOfPrefix = match.index + prefixLength;
                const startOfNumber = text.indexOf(bates.number.toString(), pos);
                if (endOfPrefix < startOfNumber) {
                    end += startOfNumber - endOfPrefix;
                }
                const byNumber = cachedDocs[bates.prefix];
                if (byNumber) {
                    const doc = byNumber[shortNumber];
                    if (doc) {
                        if (doc.id === 0 || !isValidBatesForLinking(bates)) {
                            // We already looked up this bates number, and it didn't match any existing
                            // documents. Include the text in the note without linking it.
                            if (replacement) {
                                Dom.addContent(replacement, text.substring(pos, end));
                            }
                        } else {
                            if (replacement) {
                                Dom.addContent(replacement, text.substring(pos, match.index));
                            } else {
                                replacement = Dom.fragment(text.substring(0, match.index));
                            }
                            // Adjust end for actual page and suffix.
                            if (
                                bates.page
                                && doc.page
                                && bates.page.separator === doc.page.separator
                                && bates.page.number === doc.page.number
                            ) {
                                end += bates.page.toString().length;
                            }
                            if (bates.suffix === doc.suffix) {
                                end += bates.suffix.length;
                            }
                            Dom.addContent(
                                replacement,
                                getLink(doc.id, text.substring(match.index, end)),
                            );
                        }
                        pos = end;
                        continue;
                    }
                }
                if (lookups && isValidBatesForLinking(bates)) {
                    lookups.push(bates);
                }
                if (replacement) {
                    // Leave the text as-is; it may be updated later on.
                    Dom.addContent(replacement, text.substring(pos, end));
                }
                pos = end;
            }
            if (replacement) {
                const remainder = text.substring(pos, text.length);
                if (remainder) {
                    Dom.addContent(replacement, remainder);
                }
            } // else we made no changes; we'll return undefined, and the original text node will remain
            return replacement;
        });
        if (lookups && lookups.length > 0) {
            if (batesLinkTimeoutId) {
                clearTimeout(batesLinkTimeoutId);
            }
            // Update the node using the newly cached docs, but don't perform any new lookups.
            batesLinkRateLimit.nodeToCallback.set(node, () =>
                insertBatesLinks(node, getLink, true),
            );
            lookups.forEach((l) => {
                const numSet = batesLinkRateLimit.lookups.get(l.prefix) || new Set();
                numSet.add(l.number);
                batesLinkRateLimit.lookups.set(l.prefix, numSet);
            });
            batesLinkTimeoutId = setTimeout(() => {
                const allCallbacks = batesLinkRateLimit.nodeToCallback;
                const allLookups: { prefix: string; number: Number }[] = [];
                batesLinkRateLimit.lookups.forEach((numbers, prefix) => {
                    allLookups.push(
                        ...Array.from(numbers || []).map((number) => {
                            return { prefix, number };
                        }),
                    );
                });
                batesLinkRateLimit.nodeToCallback = new Map();
                batesLinkRateLimit.lookups = new Map();
                batesLinkTimeoutId = null;
                Rest.post("documents/matchBates.rest", {
                    prefixes: allLookups.map((b) => b.prefix),
                    nums: allLookups.map((b) => b.number.toJSON()),
                }).then(
                    (docs: CachedDocument[] /* not quite; page is a string */) => {
                        // Cache all of the new links.
                        docs.forEach((d) => {
                            if (d.page) {
                                // Convert the page, which is actually a string.
                                d.page = Bates.Page.fromString(String(d.page));
                            }
                            const byNumber = (cachedDocs[d.prefix] = cachedDocs[d.prefix] || {});
                            byNumber[d.shortNumber] = d;
                        });
                        allCallbacks.forEach((callback, node) => callback());
                    },
                    () => {},
                );
            }, BATES_LINK_RATE_LIMIT_MS);
        }
    }
}

export = Bates;
